import {ComponentChildren} from 'preact';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';

import Scrim from '@/components/Scrim';
import useScrollCapture from '@/hooks/use-scroll-capture';
import closeIcon from '@/icons/close-icon.svg';

export default Drawer;

/**
 * Preact drawer component.
 * May be used on the left or right side of the screen. (default is left)
 * Drawer Controller is required to open and close the drawer.
 * Children are rendered inside the drawer.
 * @param props - the props
 * @returns  the drawer component
 * @see DrawerProps
 */
function Drawer({
  controller,
  children,
  endDrawer = false,
  showCloseButton = true,
  padding = DrawerPadding.large,
}: DrawerProps) {
  const isOpen = controller.isOpen;

  useScrollCapture(isOpen);

  return (
    <>
      <Scrim
        showScrim={isOpen}
        onClick={() => controller.close()}
        className="slotted__content"
      />
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          onPostDeactivate: () => controller.close(),
        }}
      >
        <div
          className={classNames(
            'slotted__content',
            'fixed inset-0 z-50 bg-bright-white transition-all duration-300 ease-in-out md:inset-x-auto md:inset-y-0 md:w-96',
            {
              'md:right-0': endDrawer,
              'md:left-0': !endDrawer,
              'translate-x-full': !isOpen && endDrawer,
              '-translate-x-full': !isOpen && !endDrawer,
              'px-8 py-12': padding === DrawerPadding.large,
              'px-6 py-8': padding === DrawerPadding.small,
              'py-8': padding === DrawerPadding.smallTop,
            }
          )}
        >
          <button
            onClick={() => controller.close()}
            className={classNames('absolute right-6', {
              hidden: !showCloseButton,
              'top-6': padding === DrawerPadding.large,
              'top-0': padding === DrawerPadding.small,
              'top-3': padding === DrawerPadding.smallTop,
            })}
          >
            <img src={closeIcon} alt="close drawer icon" />
          </button>
          {children}
        </div>
      </FocusTrap>
    </>
  );
}

/**
 * The padding options for the drawer.
 * Choose large for more padding, small for less padding
 */
export enum DrawerPadding {
  large = 'large',
  small = 'small',
  smallTop = 'smallTop',
}

/**
 * The props for the drawer component
 * @param endDrawer - if the drawer is on the right side of the screen
 * @param showCloseButton - if the close button should be shown
 * @param controller - the drawer controller
 * @param children - the children to render inside the drawer
 * @param padding - the padding for the drawer (Large or Small)
 * @see DrawerController
 * @see Drawer
 */
export type DrawerProps = {
  controller: DrawerController;
  endDrawer?: boolean;
  showCloseButton?: boolean;
  children?: ComponentChildren;
  padding?: DrawerPadding;
};

/**
 * The drawer controller interface
 * @param isOpen - if the drawer is open
 * @param close - method to close the drawer
 * @see Drawer
 */
export type DrawerController = {
  isOpen: boolean;
  close: () => void;
};
